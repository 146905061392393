import React from 'react'

import Layout from '../components/layout'
import WorkOne from '../components/work/WorkOne'
import WorkThree from '../components/work/WorkThree'
import WorkTwo from '../components/work/WorkTwo'

const Work = props => (
  <Layout
    lang='en'
	title="Work"
	meta={{description: 'We work with great clients, from big corporations to startups that are eager to change the world.'}}
    rootProps={{ className: 'page work bg-blue' }}
    headerProps={{ isBlue: true }}
  >
    <WorkOne lang="en" />
    <WorkTwo lang="en" />
    <WorkThree lang="en" />
  </Layout>
)

export default Work
